import { Theme }            from '@material-ui/core'
import React, { ReactNode } from 'react'

import Transition, { EnterHandler, TransitionStatus } from 'react-transition-group/Transition'
import theme                                          from '../../theme'
import { makeStyles }                                 from '@material-ui/core/styles'

const duration = 500


const useFadeInStyles = makeStyles((theme) => ({
    defaultStyle: {
        opacity: 0,
        transition: `${duration}ms ease-in`,
        transitionProperty: 'opacity, transform'
    },
    entering: {
        opacity: 0
    },
    entered: {
        opacity: 1
    }
}))
const useDownStyles = makeStyles((theme: Theme) => ({
    defaultStyle: {
        // transition: `opacity ${duration}ms ease-in-out`,
        opacity: 0,
        transform: 'translateY(-10%)',
        transition: `${duration}ms ease-in`,
        transitionProperty: 'opacity, transform'
    },
    entering: {
        opacity: 0,
        transform: 'translateY(-10%)'
    },
    entered: {
        opacity: 1,
        transform: 'translateY(0)'
    }
}))
const useUpStyles = makeStyles((theme: Theme) => ({
    defaultStyle: {
        // transition: `opacity ${duration}ms ease-in-out`,
        opacity: 0,
        transform: 'translateY(10%)',
        transition: `${duration}ms ease-in`,
        transitionProperty: 'opacity, transform'
    },
    entering: {
        opacity: 0,
        transform: 'translateY(10%)'
    },
    // Transition to component being visible and having its position reset.
    entered: {
        opacity: 1,
        transform: 'translateY(0)'
    }
}))
const useLeftStyles = makeStyles((theme: Theme) => ({
    defaultStyle: {
        // transition: `opacity ${duration}ms ease-in-out`,
        opacity: 0,
        transform: 'translateX(10%)',
        transition: `${duration}ms ease-in`,
        transitionProperty: 'opacity, transform'
    },
    entering: {
        opacity: 0,
        transform: 'translateX(10%)'
    },
    // Transition to component being visible and having its position reset.
    entered: {
        opacity: 1,
        transform: 'translateX(0)'
    }
}))
const useRightStyles = makeStyles((theme: Theme) => ({
    defaultStyle: {
        opacity: 0,
        transform: 'translateX(-10%)',
        transition: `${duration}ms ease-in`,
        transitionProperty: 'opacity, transform'
    },
    entering: {
        opacity: 0,
        transform: 'translateX(-10%)'
    },
    // Transition to component being visible and having its position reset.
    entered: {
        opacity: 1,
        transform: 'translateX(0)'
    }
}))
const useUpDownStyles = makeStyles((theme: Theme) => ({
    defaultStyle: {
        transform: 'translateX(-10%)',
        transition: `${duration}ms ease-in`,
        transitionProperty: 'transform'
    },
    entering: {
        transform: 'translateY(0)'
    },

    entered: {
        transform: 'translateX(70%)'
    },

    exiting: {
        transform: 'translateX(70%)'
    },

    exited: {
        transform: 'translateX(0)'
    }
}))
const useAwayStyles = makeStyles((theme: Theme) => ({
    defaultStyle: {
        opacity: 0,
        transform: 'translateY(-20%)',
        transition: `${duration}ms ease-in`,
        transitionProperty: 'opacity, transform'
    },
    entering: {
        opacity: 0,
        transform: 'translateY(-20%)'
    },
    // Transition to component being visible and having its position reset.
    entered: {
        opacity: 1,
        transform: 'translateY(0%)'
    }
    // exiting: {
    //     opacity: 1,
    //     transform: 'translateY(0%)'
    // },
    // // Transition to component being visible and having its position reset.
    // exited: {
    //     opacity: 0,
    //     transform: 'translateY(-20%)'
    // },
}))

type AnimationDirection = 'left' | 'right' | 'up' | 'down' | 'updown' | 'away' | 'fade'

interface FadeAndSlideProps {
    direction: AnimationDirection
    duration?: number
    children?: ReactNode
    inProp?: boolean
    onEntered?: EnterHandler
}

export const FadeAndSlide = ({
                                 duration = 500, // default animation duration
                                 direction,
                                 children,
                                 inProp,
                                 onEntered
                             }: FadeAndSlideProps) => {

    let classes: Record<string, string>


    switch (direction) {
        case 'fade':
            classes = useFadeInStyles(theme)
            break
        case 'up':
            classes = useUpStyles(theme)
            break
        case 'down':
            classes = useDownStyles(theme)
            break
        case 'left':
            classes = useLeftStyles(theme)
            break
        case 'right':
            classes = useRightStyles(theme)
            break
        case 'updown':
            classes = useUpDownStyles(theme)
            break
        case 'away':
            classes = useAwayStyles(theme)
            break
        default:
            classes = useFadeInStyles(theme)
    }

    if (inProp == null) {
        inProp = true
    }

    if (onEntered == null) {
        onEntered = function noop() {
        }
    }

    const transitionStatusClasses = (state: TransitionStatus) => {
        switch (state) {
            case 'entered':
                return classes.entered
            case 'entering':
                return classes.entering
            case 'exiting':
                return classes.exiting
            case 'exited':
                return classes.exited
            default:
                return
        }
    }

    return (
        <Transition appear={true} in={inProp} onEntered={onEntered} timeout={300}>
            {
                (state: TransitionStatus) => (
                    <div className={`${classes.defaultStyle} ${transitionStatusClasses(state)}`}>
                        {children}
                    </div>
                )
            }
        </Transition>
    )
}

