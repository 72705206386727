"use client"
import React, {FunctionComponent}                    from 'react'
import {Container, Theme, ThemeProvider, Typography} from '@material-ui/core'
import {makeStyles}                                  from '@material-ui/core/styles'
import Layout                                        from '../components/shared/layout'
import theme                                         from '../theme'
import {GatsbyImage}                                 from "gatsby-plugin-image";
import {graphql, Link}                               from 'gatsby'
import {FadeAndSlide}                                from '../components/animations/FadeSlideAnimations'
import SanityImage                                   from 'gatsby-plugin-sanity-image'


export type Node<T> = { node: T }

export type AllSanityClerk = {
    edges: Node<Clerk>[]
}

export type Slug = { current: string }

export type Clerk = {
    firstName: string
    lastName: string
    preferredName: string
    jobTitle: string
    _rawBiography: any[]
    clerkPortrait: { asset: { fluid: any }, crop: any, hotspot: any }
    slug: Slug
}

export const query = graphql`
    query {
        placeholderImage: sanitySiteSettings {
            siteMedia {
                peoplePlaceholder {
                    ...ImageData
                }
            }
        }
        clerkList: allSanityClerk {
            edges {
                node {
                    preferredName
                    jobTitle
                    slug {
                        current
                    }
                    clerkPortrait {
                        ...PortraitData
                    }
                }
            }
        }
    }
`


type ClerksPageProps = {
    data: {
        clerkList: AllSanityClerk,
        placeholderImage: { siteMedia: { peoplePlaceholder: { asset: { fluid: any } } } }
    }
}

const useStyles = makeStyles((theme: Theme) => ({
    pageTitle: {
        color: '#FFF',
        fontWeight: 500,
        margin: '0.5rem 0 0.7rem',
        textAlign: 'center'

    },
    peopleGrid: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        height: 'auto',
        justifyContent: 'center',
        gridGap: '1em',
        gap: '1em',
        justifyItems: 'center',
        justifySelf: 'center',
        minHeight: '100vh'
    },
    peopleCard: {
        flex: '0 1 320px',
        margin: '1em'
    },

    barristerCard: {
        position: 'relative',
        cursor: 'pointer',
        background: 'rgba(109, 110, 113, 0.3)',
        width: '340px',
        height: '450px',
        maxWidth: '340px',
        maxHeight: '450px'
    },
    cardTitle: {
        fontSize: '24px',
        textAlign: 'center',
        color: '#FFF',
        padding: '1em 0em',
        '&:hover': {
            color: 'goldenrod'
        }
    },
    cardDetailsContainer: {
        background: '#1C1818',
        width: '100%',
        position: 'absolute',
        bottom: 0,
        left: 0,
        transition: '0.5s'
    },
    cardDetailsText: {
        textAlign: 'center',
        color: '#FFF',
        textDecoration: 'none',
        fontSize: '0.8em'
    },
    shortlistButton: {
        transition: '0.5s',
        color: '#FFF',
        borderRadius: '0% 20% 20% 0%',
        '&:hover': {
            color: 'goldenrod'
        }
    }
}))

const Clerks: FunctionComponent<ClerksPageProps> = (props) => {
    const classes = useStyles(theme)
    const clerkList = props.data.clerkList.edges
    const placeholderImage = props.data.placeholderImage.siteMedia.peoplePlaceholder.asset.fluid

    return (
        <ThemeProvider theme={theme}>
            <Layout helmetData={{
                pageTitle: 'Clerks',
                metaDescription: 'The clerks at Level 22 Chambers and can help find the right person to provide legal assistance or advice',
                slug: 'clerks'
            }}
                    backgroundImage={''}>
                <Container maxWidth={'lg'}>
                    <FadeAndSlide direction={'down'}>
                        <Typography style={{color: '#FFF'}} variant={'h1'} component={'h1'}>Clerks</Typography>
                    </FadeAndSlide>
                    <FadeAndSlide direction={'fade'}>
                        <section className={classes.peopleGrid}>
                            {
                                clerkList && clerkList.map(({node}, index) => {
                                              const clerk = node
                                              return clerk && (
                                                  <div className={classes.peopleCard}
                                                       key={index}>
                                                      <Link to={`/clerks/${clerk.slug.current}`}>

                                                          <div className={classes.barristerCard}>
                                                              {
                                                                  clerk.clerkPortrait ? (
                                                                      <SanityImage
                                                                          width={340}
                                                                          {...clerk.clerkPortrait}
                                                                          style={
                                                                              {
                                                                                  overflow: 'hidden',
                                                                                  position: 'relative',
                                                                                  margin: 0,
                                                                                  width: '100%',
                                                                                  height: '100%',
                                                                                  objectFit: 'cover'
                                                                              }}
                                                                          imgStyle={{
                                                                              display: 'block',
                                                                              objectPosition: `0 ${(clerk.clerkPortrait.crop ? (clerk.clerkPortrait.crop.bottom * 100).toString() : '0') || 0}%`,
                                                                              width: `340px`
                                                                          }}/>
                                                                  ) : (
                                                                      <GatsbyImage
                                                                          image={placeholderImage}
                                                                          style={{
                                                                              overflow: 'hidden',
                                                                              position: 'relative',
                                                                              margin: 0,
                                                                              width: '100%',
                                                                              height: '100%'
                                                                          }}
                                                                          imgStyle={{
                                                                              display: 'block',
                                                                              left: `0`,
                                                                              width: `800px`,
                                                                              top: '-30px'
                                                                          }}/>
                                                                  )
                                                              }
                                                              <div className={classes.cardDetailsContainer}>
                                                                  <Typography className={classes.cardTitle} variant="h5">
                                                                      {clerk.preferredName}<br/>
                                                                      <span className={classes.cardDetailsText}>
                                {clerk.jobTitle}
                              </span>
                                                                  </Typography>
                                                              </div>
                                                          </div>
                                                      </Link>
                                                  </div>
                                              );
                                          })
                            }
                        </section>
                    </FadeAndSlide>
                </Container>
            </Layout>
        </ThemeProvider>
    );
}

export default Clerks
